import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { HorizontalColorlibConnector } from "./ColorlibConnector";
import { ColorlibStepIcon } from "./ColorlibStepIcon";
import stepsData from "../../data/subtasks.json";

export const HorizontalStepper = ({
  subTasks,
  activeStep,
  handleBack,
  handleNext,
}) => {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<HorizontalColorlibConnector />}
      >
        {subTasks.map((task, index) => (
          <Step key={task.id}>
            <StepLabel
              StepIconComponent={(props) => (
                <ColorlibStepIcon
                  {...props}
                  iconUrl={stepsData[index].iconUrl}
                />
              )}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <label style={{ fontSize: "15px" }}>{task.name}</label>
                <label>
                  {new Date(parseInt(task.due_date)).toLocaleDateString()}
                </label>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        <Button
          disabled={activeStep === -1}
          onClick={handleBack}
          variant="contained"
          style={{ margin: "0 40px" }}
          sx={{
            margin: "0 40px",
            background:
              "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
            "&.Mui-disabled": {
              background:
                "linear-gradient(264.28deg, #51334d, #883eb1 51.56%, #1f4a5f)",
              color: "white",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStep === subTasks.length - 1}
          style={{ margin: "0 40px" }}
          sx={{
            margin: "0 40px",
            background:
              "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
            "&.Mui-disabled": {
              background:
                "linear-gradient(264.28deg, #51334d, #883eb1 51.56%, #1f4a5f)",
              color: "white",
            },
          }}
        >
          Next
        </Button>
      </div>
    </Stack>
  );
};
