import { Slide, stepConnectorClasses } from "@mui/material";
import StepConnector from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";

export const VerticalColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 100,
      width: 3,
      border: 0,
      margin: '-10px 0px -10px 14px',
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  
export const HorizontalColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));