import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { VerticalColorlibConnector } from "./ColorlibConnector";
import { ColorlibStepIcon } from "./ColorlibStepIcon";
import stepsData from "../../data/subtasks.json";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useState } from "react";
import { Button } from "@mui/material";

export const VerticalStepper = ({
  subTasks,
  name,
  attachments,
  activeStep,
  handleBack,
  handleNext,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = async (index, isExpanded) => {
    setExpanded(isExpanded ? index : false);
  };
  return (
    <>
      <p className="text-3xl mx-4 mb-6 text-white text-center">{name}</p>
      <Stack
        sx={{ alignItems: "center", justifyContent: "center" }}
        spacing={4}
      >
        <Stepper
          activeStep={activeStep}
          connector={<VerticalColorlibConnector />}
          orientation="vertical"
          sx={{ maxWidth: "80%" }}
        >
          {subTasks.map((task, index) => (
            <Step key={task.id}>
              <StepLabel
                StepIconComponent={(props) => (
                  <ColorlibStepIcon
                    {...props}
                    iconUrl={stepsData[index].iconUrl}
                  />
                )}
                sx={(theme) => ({
                  alignItems: " start",
                  "& .MuiStepLabel-labelContainer": {
                    position: "relative",
                    paddingLeft: "40px",
                    marginLeft: "-32px",
                  },
                  "& .MuiStepLabel-labelContainer::before": index !==
                    subTasks.length - 1 && {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width: 3 /* Adjust the width of the border */,
                    background:
                      index < activeStep
                        ? "linear-gradient(264.28deg, #1cb4ff, #b53df9 51.56%, #f034d6)"
                        : "white",
                  },
                })}
              >
                <Accordion
                  sx={{
                    backgroundColor: "#0A0A2D",
                    boxShadow:
                      "1px 2px 2px 1px rgba(255,255,255,0.2),3px 1px 9px 0px rgba(255,255,255,0.14),0px 1px 3px 0px rgba(255,255,255,0.12)",
                  }}
                  expanded={index === expanded}
                  onChange={(e, isExpanded) => {
                    handleAccordionChange(index, isExpanded);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <div className="flex flex-col items-center justify-center">
                      <label className="text-[15px] text-white">
                        {task.name}
                      </label>
                      <label className="text-white">
                        {new Date(parseInt(task.due_date)).toLocaleDateString()}
                      </label>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="flex flex-col gap-4 text-white">
                      {attachments[index].data.length === 0
                        ? "No attachments"
                        : attachments[index].data.map((attach) => (
                            <div key={attach.taskId} className="flex gap-4">
                              <img
                                className="w-6 h-6"
                                src={`${attach.url_w_query}`}
                                alt="attachment"
                                loading="lazy"
                              />
                              <a
                                className="hover:text-red-500"
                                target="_blank"
                                href={attach.url_w_query}
                              >
                                {attach.title.split('.').slice(0, -1).join('.')}
                              </a>
                            </div>
                          ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </StepLabel>
              {/* <StepContent sx = {{
                marginLeft: '26px',
                borderLeft: index === subTasks.length -1 ? 'none' : '3px solid white',
                marginTop: '-8px'
            }}>
            </StepContent> */}
            </Step>
          ))}
        </Stepper>
        {/* <div>
        <Button
          disabled={activeStep === -1}
          onClick={handleBack}
          variant="contained"
          style={{ margin: "0 40px" }}
          sx={{
            margin: "0 40px",
            background:
              "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
            "&.Mui-disabled": {
              background:
                "linear-gradient(264.28deg, #51334d, #883eb1 51.56%, #1f4a5f)",
              color: "white",
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStep === subTasks.length - 1}
          style={{ margin: "0 40px" }}
          sx={{
            margin: "0 40px",
            background:
              "linear-gradient(264.28deg, #f034d6, #b53df9 51.56%, #1cb4ff)",
            "&.Mui-disabled": {
              background:
                "linear-gradient(264.28deg, #51334d, #883eb1 51.56%, #1f4a5f)",
              color: "white",
            },
          }}
        >
          Next
        </Button>
      </div> */}
      </Stack>
    </>
  );
};
