import background from "../../asset/background.svg";
import astronaut from "../../asset/astronaut.webp";

const Background = () => {
  return (
    <div className="background">
      <div className="background-image">
        <img src={background} />
      </div>
      <div className="astronaut-image">
        <img src={astronaut} />
      </div>
    </div>
  );
};

export default Background;
