import axios from "axios";
import { VerticalStepper } from "./Vertical";
import React, { useEffect, useState } from "react";
import { HorizontalStepper } from "./Horizontal";
import Spinner from "../Spinner";
import { useParams } from "react-router-dom";

const Stepper = ({ type }) => {

  const [loading, setLoading] = useState(false);
  const { taskId } = useParams();

  const [attachments, setAttachments] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [activeStep, setActiveStep] = useState(-1);

  const getProjectStatus = async () => {
    const options = {
      headers: {
        Authorization: process.env.REACT_APP_CLICKUP_API_TOKEN,
      },
    };

    try {
      const response = await axios.get(
        "https://api.clickup.com/api/v2/list/901103847094/task",
        options
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching data from ClickUp:", error);
    }
  };

  const getTaskAttachments = async (taskId) => {
    if (!taskId) return;

    const query = new URLSearchParams({
      custom_task_ids: "true",
      include_markdown_description: "true",
      custom_fields: "Time Allotment",
    }).toString();

    const options = {
      headers: {
        Authorization: process.env.REACT_APP_CLICKUP_API_TOKEN,
      },
    };
    try {
      const response = await axios.get(
        `https://api.clickup.com/api/v2/task/${taskId}?${query}`,
        options
      );
      const data = response.data.attachments;

      return data;
    } catch (error) {
      console.error("Error fetching data from ClickUp:", error);
    }
  };

  const getTaskStatus = async (taskId) => {
    setLoading(true);

    const query = new URLSearchParams({
      custom_task_ids: "true",
      // team_id: '123',
      include_subtasks: "true",
      include_markdown_description: "true",
      custom_fields: "Time Allotment",
    }).toString();
    // const taskId = "86893h29n";

    const options = {
      headers: {
        Authorization: process.env.REACT_APP_CLICKUP_API_TOKEN,
      },
    };

    try {
      const response = await axios.get(
        `https://api.clickup.com/api/v2/task/${taskId}?${query}`,
        options
      );
      const data = response.data.subtasks;
      setSubTasks(data);
      setTaskTitle(response.data.name);
      let i = 0;
      for (i = 0; i < data.length; i++) {
        if (data[i].status.status !== "done") {
          break;
        }
      }
      setActiveStep(i - 1);

      const attach = await Promise.all(
        data.map(async (task) => {
          const data = await getTaskAttachments(task.id);
          return {
            taskId: task.id,
            data: data,
          };
        })
      );

      setAttachments(attach);
      setLoading(false);
      return response.data;
    } catch (error) {
      console.error("Error fetching data from ClickUp:", error);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    getTaskStatus(taskId);
  }, [taskId]);

  return loading ? (
    <Spinner />
  ) : type === "vertical" ? (
    <VerticalStepper
      name = {taskTitle}
      subTasks={subTasks}
      attachments={attachments}
      activeStep={activeStep}
      getTaskAttachments={getTaskAttachments}
      handleBack={handleBack}
      handleNext={handleNext}
    />
  ) : (
    <HorizontalStepper
      subTasks={subTasks}
      activeStep={activeStep}
      handleBack={handleBack}
      handleNext={handleNext}
    />
  );
};

export default Stepper;
